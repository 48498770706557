import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { hideHover, hover, imageStyle, root } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import GatsbyLink from '../gatsby-link';
import imageUrlFor from '../../utils/image-url-for';

const ProjectPreview = ({ categorySlug, heroImage, slug, title }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const toggleMouseOver = () => {
    setIsMouseOver(!isMouseOver);
  };

  return (
    <GatsbyLink
      className={root}
      to={`/projects/${categorySlug}/${slug.current}`}
      onMouseEnter={toggleMouseOver}
      onMouseLeave={toggleMouseOver}
    >
      <div className={imageStyle}>
        {heroImage && heroImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(heroImage)).width(431).height(298).quality(80).url()}
            alt={heroImage.alt}
          />
        )}
        <div className={isMouseOver ? hover : hideHover}>
          <h3>{title}</h3>
        </div>
      </div>
    </GatsbyLink>
  );
};

ProjectPreview.propTypes = {
  categorySlug: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
  slug: PropTypes.shape({
    current: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
};

ProjectPreview.defaultProps = {
  title: '',
};

export default ProjectPreview;
