import PropTypes from 'prop-types';
import React from 'react';

import { col, root, videoStyle } from './index.module.scss';
import Video from '../video';

const LargeVideo = ({ muxVideo }) => (
  <section className={root}>
    <div className={col}>
      <Video playbackId={muxVideo.asset.playbackId} className={videoStyle} />
    </div>
  </section>
);

LargeVideo.propTypes = {
  muxVideo: PropTypes.shape({
    asset: PropTypes.shape({
      playbackId: PropTypes.string,
    }),
  }).isRequired,
};

export default LargeVideo;
