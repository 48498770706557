import PropTypes from 'prop-types';
import React from 'react';

import { col, colTwo, root } from './index.module.scss';
import BlockContent from '../block-content';
import Video from '../video';

const VideoWithCopy = (props) => {
  const { copy, muxVideo, orientation } = props;

  return orientation === 'leftVideoWithCopy' ? (
    <section className={root}>
      <div className={colTwo}>
        <Video playbackId={muxVideo.asset.playbackId} />
      </div>
      <div className={col}>{copy && <BlockContent blocks={copy} />}</div>
    </section>
  ) : (
    <section className={root}>
      <div className={col}>{copy && <BlockContent blocks={copy} />}</div>
      <div className={colTwo}>
        <Video playbackId={muxVideo.asset.playbackId} />
      </div>
    </section>
  );
};

VideoWithCopy.propTypes = {
  copy: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  muxVideo: PropTypes.shape({
    asset: PropTypes.shape({
      playbackId: PropTypes.string,
    }),
  }).isRequired,
  orientation: PropTypes.string.isRequired,
};

export default VideoWithCopy;
