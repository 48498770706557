import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { description, projects, root } from './index.module.scss';
import ProjectPreview from '../project-preview';

const RelatedProjects = (props) => {
  const { relatedProjects } = props;
  return (
    <div className={root}>
      <h2>
        <span>Related Projects</span>
      </h2>
      <div className={description}>View some of our other projects similar to this in both scale and budget.</div>
      <div className={projects}>
        {relatedProjects &&
          _.map(relatedProjects, (project, index) => (
            <ProjectPreview key={index} {...project} categorySlug={project.categories[0].slug.current} />
          ))}
      </div>
    </div>
  );
};

RelatedProjects.propTypes = {
  relatedProjects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RelatedProjects;
